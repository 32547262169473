import React from "react";

function Form() {
  return (
    <div class="contact_form">
      <p>First Name</p>
      <input type="text" name="firstname" placeholder="First Name" />
      <p>Last Name</p>
      <input type="text" name="lastname" placeholder="Last Name" />
      <p>Company</p>
      <input type="text" name="company" placeholder="Company" />
      <p>Email</p>
      <input type="text" name="email" placeholder="Email" />
      <p>Message</p>
      <textarea name="message" id="message" placeholder="Message"  rows={5}></textarea>
      <div>
        <button>Submit</button>
      </div>
    </div>
  );
}

export default Form;
