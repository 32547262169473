import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LanguageIcon from "@mui/icons-material/Language";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import MapIcon from "@mui/icons-material/Map";
import SchoolIcon from "@mui/icons-material/School";

const works = [
  { icon: FavoriteBorderIcon, title: "immunization programs" },
  { icon: PeopleAltIcon, title: "health education" },
  { icon: LanguageIcon, title: "public health policy" },
  { icon: VaccinesIcon, title: "vaccination research" },
  { icon: TrendingUpIcon, title: "epidemic response" },
  { icon: VolunteerActivismIcon, title: "commmunity outreach" },
  { icon: PeopleAltIcon, title: "training health workers" },
  { icon: MapsHomeWorkIcon, title: "health infrastructure" },
  { icon: BusinessCenterIcon, title: "supply chain management" },
  { icon: MapIcon, title: "geospatial analysis" },
  { icon: SchoolIcon, title: "research partnership" },
];

function OurWork() {
  return (
    <div className="homepage_our_work">
      <h1>Our Work</h1>
      <p>
        AfriVacx Health Aid Foundation is a non-profit organization dedicated to
        improving healthcare across Africa. We partner governments, NGOs and
        industries to develop and implement comprehensive vaccination programs,
        promote public health awareness, and support research and innovation in
        immunization technology.
      </p>
      <div className="homepage_our_work_icons">
        {works.map((work, index) => {
          const Icon = work.icon;
          return (
            <div key={index} className="homepage_our_work_icon">
              <Icon fontSize="large" />
              <p>{work.title}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default OurWork;
