import React from "react";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

function Address() {
  return (
    <div class="contact_address">
      <h4>Also contact us via:</h4>
      <p>
        <PhoneInTalkIcon /> 08160201617 | 09036920032
      </p>
      <p>
        <EmailIcon />
        Support@afrivacx.org
      </p>
      <p>
        <LocationOnIcon />
        412 JH Wariya Crescent Life camp, Abuja Nigeria.
      </p>
    </div>
  );
}

export default Address;
