import React from "react";

function History() {
  return (
    <div class="about_history">
      <h4>Our history</h4>
      <p>
        As in other developing countries, Nigeria faces the problem of zero dose
        population as a result of factors which include; inadequate knowledge on
        immunization, distance to access point, financial deprivation, and
        distrust in vaccines and immunization programs. With this issue bearing
        down on Nigeria, the founder and board of executives determine to
        contribute meaningfully towards eradicating zero dose population in
        Nigeria is people who have never had a vaccination. AfriVacx Health Aid
        Foundation is the brainchild of Pharmacist Okeke, Bethel Ikenna (IM,
        CSCA). The establishment of the foundation in October, 2023 is to
        contribute towards achieving vaccine equity and promoting awareness of
        the availability and benefits of vaccines in Nigeria.
      </p>
    </div>
  );
}

export default History;
