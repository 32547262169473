import React from "react";
import Partner1 from "./partner1.png";
import Partner2 from "./partner2.png";
import Partner3 from "./partner3.png";

const partners = [
  {
    logo: Partner1,
    name: "Partner 1",
  },
  {
    logo: Partner2,
    name: "Partner 2",
  },
  {
    logo: Partner3,
    name: "Partner 3",
  },
];

function Partners() {
  return (
    <div class="about_partners">
      <h4>Our partners</h4>
      <div class="about_partners_container">
        {partners.map((partner) => (
          <div className="about_partner">
            <img src={partner.logo} alt={partner.name} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Partners;
