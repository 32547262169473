import React from "react";
import "./Vaccines.css";
import Header from "../../components/Header";
import intro from "./components/intro.json";
import measles from "./components/measles.json";
import polio from "./components/polio.json";
import introimg from "./components/intro.jpeg";
import measlesimg from "./components/mmr.jpeg";
import polioimg from "./components/polio.jpeg";

const vaccineData = [
  { data: intro, image: introimg },
  { data: measles, image: measlesimg },
  { data: polio, image: polioimg },
];

function Vaccines() {
  return (
    <div class="vaccines">
      <Header />
      <h2>Vaccine Science</h2>
      <p>Increase your knowledge about vaccines.</p>
      <div class="vaccine_science">
        {vaccineData.map((vaccine) => (
          <div class="vaccine">
            <img src={vaccine.image} alt={vaccine.data.title} />
            <h6>{vaccine.data.title}</h6>
            <p>{vaccine.data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Vaccines;
