import React, { useState } from "react";

const programs = [
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134954/20240206_204716_cdtjas.png",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134934/20240210_153402_zejazl.png",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126880/20240312_232127_cobbxx.png",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714125990/20240417_111513_mrlb8q.png",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126004/20240421_215913_lgqad0.png",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126919/20240312_111315_hpb6ul.png",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714126900/20240310_151427_gdmugu.png",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134928/20240126_094036_o3mmxg.png",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714150708/WhatsApp_Image_2024-04-26_at_15.48.47_gy3y68.jpg",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714150979/WhatsApp_Image_2024-04-26_at_15.48.48_1_bfbvtp.jpg",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152906/WhatsApp_Image_2024-04-26_at_15.48.48_jyszx8.jpg",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152591/IMG_4581_xwszw7.jpg",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152519/IMG_4_kvekfq.jpg",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152516/IMG_3_zkuzrq.jpg",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152621/WhatsApp_Image_2024-04-26_at_18.26.48_sxejyg.jpg",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152605/WhatsApp_Image_2024-04-26_at_18.23.53_hnx51c.jpg",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714152609/WhatsApp_Image_2024-04-26_at_18.26.11_negoyv.jpg",
  },
];

function PreviousPrograms() {
  const [show, setShow] = useState(false);

  return (
    <div class="previous_programs">
      {!show ? (
        <div class="show_programs">
          <button onClick={() => setShow(true)}>
            View our previous programs
          </button>
        </div>
      ) : (
          <div className="previous_programs_cards_container"> 
          <h4>Previous programs</h4>
          <div class="previous_programs_cards">
            {programs.map((program) => (
              <div class="previous_programs_card">
                <img src={program.src} alt="Placeholder" />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PreviousPrograms;
