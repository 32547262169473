import React from "react";

function Banner() {
  return (
    <div class="donations_banner">
      <div class="donations_banner_background">
        <h1>Give the Gift of Protection – Donate to Vaccinate!</h1>
        <div></div>
      </div>
    </div>
  );
}

export default Banner;
