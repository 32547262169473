import React from "react";
import "./Donations.css";
import Header from "../../components/Header";
import Banner from "./components/Banner";

function Donations() {
  return (
    <div className="donations">
      <Header />
      <Banner />
      <div className="donations_content">
        <p>
          Thank you for your generous support! Your donation helps provide
          life-saving vaccines to those in need, protecting individuals,
          families, and communities from preventable diseases. Together, we are
          making a difference—one shot at a time!
        </p>
        <p>
          To proceed, please reach out to us using the contact details below.
        </p>
        <h4>Founder: bethel.okeke@afrivacx.org</h4>
        <h4>Phone & Whatsapp: 08160201617 | 09036920032</h4>
      </div>
    </div>
  );
}

export default Donations;
