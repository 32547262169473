import React from "react";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();

  return (
    <div class="programs_banner">
      <div class="programs_banner_background">
        <h1>
          Vaccines save lives. Help us bring them to those who need them the
          most.
        </h1>
        <div>
          <button class="blue" onClick={() => navigate("/donate")}>
            Donate
          </button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
