import React from "react";
import "./Contact.css";
import Header from "../../components/Header";
import Form from "./components/Form";
import Address from "./components/Address";
import JoinUs from "./components/JoinUs";

function Contact() {
    return <div class="contact">
        <h2>Contact</h2>
        <Header />
        <Form />
      <Address />
      <JoinUs />
  </div>;
}

export default Contact;
