import React from 'react'

function Vision() {
  return (
    <div class="about_vision">
      <h4>Our vision</h4>
      <p>
        Our vision is a healthier, more resilient Africa where preventable
        diseases are eradicated through equitable vaccine distribution and
        comprehensive public awareness, fostering a brighter future for all
        communities.
      </p>
    </div>
  );
}

export default Vision