import React from "react";

const afrivacxPrograms = [
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134954/20240206_204716_cdtjas.png",
    title: "Rural Vaccination Access Initiative (RVAI)",
    description:
      "Deploying mobile vaccination units to underserved areas, this program ensures immunization access for rural communities.",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134954/20240206_204716_cdtjas.png",
    title: "Digital Vaccine Education and Awareness Campaign",
    description:
      "A technology-driven initiative providing accurate vaccine information to combat misinformation and boost public confidence.",
  },
  {
    src: "https://res.cloudinary.com/dmzo3ogl5/image/upload/v1714134954/20240206_204716_cdtjas.png",
    title: "Cold Chain Optimization Program (CCOP)",
    description:
      "Enhancing vaccine storage and transport with advanced refrigeration and monitoring to ensure potency and reduce wastage.",
  },
];

function OnGoingPrograms() {
  return (
    <div class="on_going_programs">
      <h4>Ongoing Programs</h4>
      <div class="program_cards">
        {afrivacxPrograms.map((program) => (
            <div class="program_card">
            <img src={program.src} alt="program" />
            <h6>{program.title}</h6>
            <p>{program.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OnGoingPrograms;
