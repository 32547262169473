import bethel from "./bethel.jpeg";

const boardmembersdetails = [
  {
    name: "Okeke Bethel",
    image: bethel,
    role: "Founder",
    title: "Pharmacist and Supply Chain Analyst",
    biography: [
      "Bethel Okeke is an accomplished leader, seasoned healthcare advocate, and the visionary founder of AfriVacx Health Aid Foundation. With a master's degree in Logistics and Supply Chain Management from University of Bradford in the United Kingdom, Bethel has developed a unique blend of expertise in public health, logistics, and supply chain optimization. His academic background, combined with years of hands-on experience, has shaped his approach to addressing healthcare challenges in the vaccine supply chain in Africa.",
      "As a dynamic entrepreneur and innovator, Bethel’s competencies extend beyond the realm of logistics. His deep understanding of public health systems, coupled with his expertise in vaccine distribution and access, has enabled him to effectively design and implement impactful programs. AfriVacx, under his leadership, has mobilized over 160 healthcare professionals across Nigeria, including doctors, nurses, and pharmacists, to tackle vaccine-preventable diseases like Hepatitis-B and HPV in underserved communities. His work is particularly focused on ensuring that essential healthcare services, including vaccines, reach remote and underserved populations.",
      "Bethel is also a strategic thinker with extensive knowledge of supply chain dynamics. He has leveraged his expertise to establish efficient logistics models for the distribution of medical supplies, ensuring timely delivery and minimizing wastage. This has been particularly important in optimizing vaccine supply chains across various regions, improving healthcare outcomes, and contributing to disease prevention efforts. His ability to build effective collaborations and navigate complex logistical challenges has made him a sought-after expert in the sector.",
      "A passionate advocate for health equity, Bethel’s leadership is characterized by his commitment to reducing health disparities through sustainable, community-driven solutions. His efforts in forging strategic partnerships, including with governmental bodies, international organizations, and local health authorities, have significantly expanded AfriVacx’s reach and impact. Bethel is driven by the belief that healthcare is a fundamental human right, and his work continues to inspire positive change across the African continent.",
    ],
    professional_achievements: [
      {
        achievement: "Bachelor of Pharmacy, Nigeria",
        date: "2023",
      },
      {
        achievement:
          "MSc Logistics, Data Analytics and Supply Chain Analytics, United Kingdom",
        date: "2019-2022",
      },
    ],
    email: "bethel.okeke@afrivacx.org",
    linkedin: "https://www.linkedin.com/in/bethelokeke",
    twitter: "https://twitter.com/bethelokeke",
  },
];

export default boardmembersdetails;
