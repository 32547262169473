import React from 'react'

function WhatWeDo() {
  return (
    <div class="about_what_we_do">
      <h4>What we do</h4>
      <div>
        <ul>
          <li>
            We ensure equitable access to life-saving vaccines across Africa.
          </li>
          <li>
            We advocate for equitable distribution of vaccines, regardless of
            background or location.
          </li>
          <li>
            Through targeted education campaigns and use of social media, we
            increase awareness about the importance of vaccination.
          </li>
          <li>We Increase vaccine coverage rates in underserved regions.</li>
          <li>
            We work towards eradicating preventable diseases through
            comprehensive immunization programs.
          </li>
          <li>
            We collaborate with local communities, healthcare organizations,
            Non-governmental Organisations (NGOs) and government agencies to
            enhance vaccine distribution networks.
          </li>
          <li>
            We conduct research to assess vaccine perception, identify barriers
            to vaccine access and acceptance and provide innovative solutions.
          </li>
          <li>
            We provide training and support to healthcare professionals to
            strengthen vaccination services.
          </li>
          <li>
            We engage in partnerships and alliances with international
            organizations to amplify our impact and extend our reach.
          </li>
          <li>
            We routinely measure and evaluate the effectiveness of our
            initiatives to continuously improve our efforts in bridging vaccine
            gaps across Africa.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default WhatWeDo