import React from "react";
import "./About.css";
import Banner from "./components/Banner";
import Vision from "./components/Vision";
import Partners from "./components/Partners";
import Team from "./components/Team";
import History from "./components/History";
import Header from "../../components/Header";
import WhatWeDo from "./components/Whoweare";
import JoinUs from "./components/JoinUs";

function About() {
  return (
    <div class="about">
      <Header />
      <Banner />
      <History />
      <Vision />
      <WhatWeDo />
      <Team />
      <Partners />
      <JoinUs />
    </div>
  );
}

export default About;
