import React from "react";

function ProfessionalAchievements({ professional_achievements }) {
  return (
    <div class="board_member_professional_achievements">
      <h4>Professional Achievements</h4>
      <div class="achievements">
        {professional_achievements.map((achievement) => (
          <div class="achievement">
            <div class="ball"></div>
            <div>
              <h6>{achievement.achievement}</h6>
              {/* <p>{achievement.date}</p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProfessionalAchievements;
