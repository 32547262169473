import React from "react";

function ContactInfo({ email, linkedin, twitter }) {
  return (
    <div class="board_member_contact_info" id="contact_info">
      <h4>Contact Info</h4>
      <hr />
      <div className="contact_info">
        <div>
          <p>Email</p>
          <small>{email}</small>
        </div>
        <div>
          <p>LinkedIn</p>
          <small>{linkedin}</small>
        </div>
      </div>
      <hr />
      <div class="contact_info">
        <div>
          <p>Twitter</p>
          <small>{twitter}</small>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
