import React from "react";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate();

  return (
    <div class="about_banner">
      <div class="about_banner_background">
        <div class="about_banner_body">
          <h1>
            Our mission is to ensure that every individual, regardless of their
            socioeconomic background or geographic location, has access to
            life-saving vaccines.
          </h1>
          <button onClick={() => navigate("/donate")}>Donate</button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
