import React from "react";

const InfoGen = ({data}) => {
  return (
    <div>
      <h1>{data.title}</h1> {/* Title of the overall content */}
      {data.sections.map((section, index) => (
        <div key={index} className="section">
          <h2>{section.title}</h2> {/* Section title */}
          <p>{section.content}</p> {/* Section content */}
          {/* Display features or points */}
          {section.features && (
            <ul>
              {section.features.map((feature, featureIndex) => (
                <li key={featureIndex}>{feature}</li>
              ))}
            </ul>
          )}
          {section.points && (
            <ul>
              {section.points.map((point, pointIndex) => (
                <li key={pointIndex}>{point}</li>
              ))}
            </ul>
          )}
          {/* Display steps or subsections */}
          {section.steps && (
            <ol>
              {section.steps.map((step, stepIndex) => (
                <li key={stepIndex}>{step}</li>
              ))}
            </ol>
          )}
          {section.subsections &&
            section.subsections.map((subsection, subIndex) => (
              <div key={subIndex}>
                <h3>{subsection.title}</h3>
                <p>{subsection.content}</p>
              </div>
            ))}
          {/* Display visual suggestion */}
          {section.visualSuggestion && (
            <div>
              <h4>Suggested Visuals:</h4>
              {Array.isArray(section.visualSuggestion) ? (
                <ul>
                  {section.visualSuggestion.map(
                    (suggestion, suggestionIndex) => (
                      <li key={suggestionIndex}>{suggestion}</li>
                    )
                  )}
                </ul>
              ) : (
                <p>{section.visualSuggestion}</p>
              )}
            </div>
          )}
          {/* Display innovations, myths, or milestones */}
          {section.innovations && (
            <ul>
              {section.innovations.map((innovation, innovationIndex) => (
                <li key={innovationIndex}>{innovation}</li>
              ))}
            </ul>
          )}
          {section.milestones && (
            <ul>
              {section.milestones.map((milestone, milestoneIndex) => (
                <li key={milestoneIndex}>{milestone}</li>
              ))}
            </ul>
          )}
          {section.myths && (
            <ul>
              {section.myths.map((myth, mythIndex) => (
                <li key={mythIndex}>{myth}</li>
              ))}
            </ul>
          )}
          {section.challenges && (
            <ul>
              {section.challenges.map((challenge, challengeIndex) => (
                <li key={challengeIndex}>{challenge}</li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
};

export default InfoGen;
