import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import CopyrightIcon from "@mui/icons-material/Copyright";

function Footer() {
  return (
    <div class="footer">
      <p>
        <CopyrightIcon /> AfriVacx Health Aid Foundation 2025
      </p>
      <div>
        <a href="https://x.com/Afrivacx?t=f11xjyc0CW5NIGBltV5bWw&s=09">
          <XIcon fontSize="large" />
        </a>
        <a href="https://www.linkedin.com/company/afrivacx/">
          <LinkedInIcon fontSize="large" />
        </a>
        <a href="https://instagram.com/afrivacx?igshid=OGQ5ZDc2ODk2ZA==">
          <InstagramIcon fontSize="large" />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61557019947197">
          <FacebookIcon fontSize="large" />
        </a>
        <a href="https://www.youtube.com/@AfriVacxHealthAidFoundation">
          <YouTubeIcon fontSize="large" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
