import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import BoardMembers from "./pages/BoardMembers";
import BoardMember from "./pages/BoardMember";
import Programs from "./pages/Programs";
import Vaccines from "./pages/Vaccines";
import Vaccine from "./pages/Vaccines/Vaccine";
import Donations from "./pages/Donations";

import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/team" element={<BoardMembers />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/donate" element={<Donations />} />
          <Route path="/vaccines" element={<Vaccines />} />
          <Route path="/vaccine/:id" element={<Vaccine />} />
          <Route path="/team/member/:id" element={<BoardMember />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
