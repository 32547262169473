import React from "react";
import "./Vaccines.css";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import InfoGen from "./components/InfoGen";
import intro from "./components/intro.json";
import measles from "./components/measles.json";
import polio from "./components/polio.json";
import introimg from "./components/intro.jpeg";
import measlesimg from "./components/mmr.jpeg";
import polioimg from "./components/polio.jpeg";

const vaccineData = [
  { data: intro, image: introimg },
  { data: measles, image: measlesimg },
  { data: polio, image: polioimg },
];

function Vaccine() {
  const { id } = useParams();

  return (
    <div class="vaccine_info">
      <div class="vaccine_info_img">
        <img src={measlesimg} alt="" />
      </div>
      <InfoGen data={measles} />
    </div>
  );
}

export default Vaccine;
