import React from "react";
import "./BoardMembers.css";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

import boardmember1 from "./components/bethel.jpeg";

const boardmemmbers = [
  {
    name: "Okeke Bethel",
    image: boardmember1,
    short_bio:
      "CEO and Founder of the organization with years of experience in the industry. He is a Pharmacist by profession and has a passion for helping people.",
  },
];

function BoardMembers() {
  const navigate = useNavigate();
  return (
    <div class="board_members">
      <Header />
      <div class="board_members_heading">
        {/* <div class="board_members_heading_img">
          <img src={boardmemmbers[0].image} alt={boardmemmbers[0].name} />
        </div> */}
        <div class="board_members_heading_info">
          <h5>Board of Directors</h5>
          <p>
            Meet the team that driving our mission to vaccinate every child in
            Africa. These leaders bring decades of expertise across public
            health, business, technology and social impact.
          </p>
        </div>
      </div>
      {boardmemmbers.map((member, index) => (
        <div class="board_member" key={index} onClick={() => navigate(`/team/member/${index}`)}>
          <div class="board_member_img">
            <img src={member.image} alt={member.name} />
          </div>
          <div class="board_member_info">
            <h6>{member.name}</h6>
            <p>
              {member.short_bio}
              <b>View profile</b>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default BoardMembers;
