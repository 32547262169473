import React from "react";
import Lab from "./lab.jpg";
import Vaccination from "./vaccination.webp";

const latest_news = [
  {
    title: "COVID-19 Lab Testing in fifteen African countries on going",
    image: Lab,
    time_stamp: "September 1, 2021",
  },
  {
    title: "COVID-19 Vaccination in African countries",
    image: Vaccination,
    time_stamp: "September 1, 2021",
  },
];

function LatestBlogs() {
  return (
    <div className="homepage_news">
      <h3>Blogs</h3>
      <div class="latest_news">
        {latest_news.map((news) => (
          <div class="news">
            <div class="news_img">
              <img src={news.image} alt={news.title} />
            </div>
            <div class="news_info">
              <h5>{news.title}</h5>
              <div>
                <p>{news.time_stamp}</p>
                <button>Read more</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LatestBlogs;
