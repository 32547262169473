import React from "react";
import { useNavigate } from "react-router-dom";

function JoinUs() {
  const navigate = useNavigate();
  return (
    <div class="join_us">
      <h1>Support our mission to end vaccine-preventable diseases</h1>
      <div>
        <button className="join_us_blue" onClick={() => navigate("/donate")}>
          Donate
        </button>
      </div>
    </div>
  );
}

export default JoinUs;
