import React from "react";

function Biography({ biography }) {
  return (
    <div class="board_member_biography">
      <h4>Biography</h4>
      {biography.map((para, index) => (
        <p key={index}>{para}</p>
      ))}
    </div>
  );
}

export default Biography;
