import React from "react";

function Banner() {
  return (
    <div className="homepage_banner">
      <div className="homepage_banner_background">
        <div className="homepage_banner_body">
          <h1>Bridging the gaps, saving lives and protecting health in Africa.</h1>
        </div>
      </div>
    </div>
  );
}

export default Banner;
