import React from "react";
import "./Programs.css";
import Header from "../../components/Header";
import Banner from "./components/Banner";
import OnGoingPrograms from "./components/OnGoingPrograms";
import PreviousPrograms from "./components/PreviousPrograms";
import JoinUs from "./components/JoinUs";

function Programs() {
  return (
    <div class="programs">
      <Header />
      <Banner />
      <OnGoingPrograms />
      <PreviousPrograms />
      <JoinUs />
    </div>
  );
}

export default Programs;
