import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Logo from "./logo.png";

function Header() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      fixed="top"
    >
      <Container>
        <Navbar.Brand href="/" style={{ fontWeight: "bold" }}>
          <img
            src={Logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="AfriVacx Health Aid Foundation logo"
          />{" "}
          AfriVacx Health Aid Foundation
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/programs">Programs</Nav.Link>
            <Nav.Link href="/team">Team</Nav.Link>
            <Nav.Link href="/vaccines">Vaccines</Nav.Link>
            {/* <Nav.Link href="/news">News</Nav.Link> */}
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/donate">Donate</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
