import React from "react";
import { useNavigate } from "react-router-dom";

function Header({ img, name, title, role }) {
  const navigate = useNavigate();
  return (
    <div class="board_member_header">
      <div class="board_member_header_img">
        <img src={img} alt={name} />
      </div>
      <h4>{name}</h4>
      <p>{title}</p>
      <p>{role}</p>
      {/* <div>
        <button className="gray">Donate</button>
        <button
          className="blue"
        >
          Connect
        </button>
      </div> */}
    </div>
  );
}

export default Header;
