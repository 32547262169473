import React from "react";
import "./HomePage.css";
import Header from "../../components/Header";
import Banner from "./components/Banner";
import OurWork from "./components/OurWork";
import LatestNews from "./components/LatestNews";
import JoinUs from "./components/JoinUs";
import LatestBlogs from "./components/LatestBlogs";

function HomePage() {
  return (
    <>
      <Header />
      <div className="homepage">
        <Banner />
        <OurWork />
        <LatestNews />
        <JoinUs />
        <LatestBlogs />
      </div>
    </>
  );
}

export default HomePage;
