import React from "react";

const teamMembers = [
  {
    profile_img:
      "https://res.cloudinary.com/muyi-hira-app/image/upload/v1733406309/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration_lp8qko.jpg",
    name: "Pharm. Bethel Okeke",
    role: "Founder",
    location: "Nigeria",
  },
  {
    profile_img:
      "https://res.cloudinary.com/muyi-hira-app/image/upload/v1733406309/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration_lp8qko.jpg",
    name: "Pharm. Oluebubechukwu Eze",
    role: "Board Member",
    location: "Nigeria",
  },
  {
    profile_img:
      "https://res.cloudinary.com/muyi-hira-app/image/upload/v1733406309/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration_lp8qko.jpg",
    name: "Pharm. Uzochukwu Chima",
    role: "Board Member",
    location: "Nigeria",
  },
  {
    profile_img:
      "https://res.cloudinary.com/muyi-hira-app/image/upload/v1733406309/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration_lp8qko.jpg",
    name: "Pharm. Caleb Ugorji",
    role: "Board Member",
    location: "Nigeria",
  },
];

function Team() {
  return (
    <div class="about_team">
      <h4>Our team</h4>
      <div class="about_team_members">
        {teamMembers.map((member) => (
          <div class="about_team_member">
            <div class="about_team_member_img">
              <img src={member.profile_img} alt="profile" />
            </div>
            <div class="about_team_member_info">
              <div>
                <h4>{member.name}</h4>
                <p>{member.role}</p>
                <p>{member.location}</p>
              </div>
              <div>
                <button>View profile</button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
