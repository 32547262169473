import React from "react";
import { useParams } from "react-router-dom";
import "./BoardMember.css";
import boardmembersdetails from "./components/boardmembersdetails";
import Header from "./components/Header";
import ContactInfo from "./components/ContactInfo";
import Contributions from "./components/Contributions";
import Biography from "./components/Biography";
import ProfessionalAchievements from "./components/ProfessionalAchievements";

function BoardMember() {
  const { id } = useParams();
  return (
    <div class="board_member_profile">
      <Header
        name={boardmembersdetails[id].name}
        title={boardmembersdetails[id].title}
        role={boardmembersdetails[id].role}
        img={boardmembersdetails[id].image}
      />
      <Biography biography={boardmembersdetails[id].biography} />
      <ProfessionalAchievements
        professional_achievements={
          boardmembersdetails[id].professional_achievements
        }
      />
      {/* <Contributions contributions={boardmembersdetails[id].contribution} /> */}
      <ContactInfo
        email={boardmembersdetails[id].email}
        linkedin={boardmembersdetails[id].linkedin}
        twitter={boardmembersdetails[id].twitter}
      />
    </div>
  );
}

export default BoardMember;
